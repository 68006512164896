<template>
  <section class="onglets-container">
    <b-nav class="menu-onglets" tabs>
      <template v-for="route in routes">
        <b-nav-item
          :ref="`route-${route.name}`"
          :key="route.name"
          :to="{ name: route.name, params: $route.params }"
        >
          <div class="nav-link-content">
            <component
              v-if="route.meta.customIcon"
              :is="route.meta.customIcon"
              class="d-none d-md-inline-block"
            ></component>
            <b-icon
              v-else-if="route.meta.icon"
              :icon="route.meta.icon"
              class="d-none d-md-inline-block"
            ></b-icon>
            {{ route.meta.title }}
          </div>
        </b-nav-item>
        <span :key="`${route.name}-separator`" v-if="route.meta.dividerAfter">
          <hr />
        </span>
      </template>
    </b-nav>
  </section>
</template>

<script>
export default {
  name: "TabsMenuComponent",
  props: ["parent"],
  data() {
    return {
      routes: this.$router.getRoutes()
        .filter((r) => r.parent?.name === this.parent)
        .filter((r) => r.meta?.tabbable === true)
        .filter((r) => !r.meta?.access || r.meta.access(this.$store))
    };
  },
  mounted() {
    this.$refs[`route-${this.$route.name}`][0].scrollIntoView({
      inline: "center",
    });
  },
};
</script>
